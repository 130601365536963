<template>
  <div class="filters-form">
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.date_event_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_event_f_from" /></div>
          <div><input-date-picker v-model="date_event_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.link_builder_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_builder_f"
              @changeValue="changeValueLink_builder_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.editor_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="editor_f"
              @changeValue="changeValueEditor_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.entity_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="entity_f"
              @changeValue="changeValueEntity_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.action_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="action_f"
              @changeValue="changeValueAction_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.company_name_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="company_name_f"
              @changeValue="changeValueCompany_name_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.category_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="category_f"
              @changeValue="changeValueCategory_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column"></div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputSelect from '@/components/inputs/InputSelect'
import { fetchData, urls } from '@/utils/urls.js'
import { filtersMixin } from '@/utils/filters-mixin'

export default {
  name: 'SetFiltersLinks',
  mixins: [filtersMixin],
  components: { InputDatePicker, InputSelect /* InputText, InputNumber*/ },

  data() {
    return {
      partner_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      entity_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      action_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      company_name_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      category_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      editor_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      link_builder_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      date_event_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_event_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      labels: {
        date_event_f_from: 'Date event',
        date_event_f_to: 'Date event',
        partner_f: 'Partner',
        link_builder_f: 'Link Builder',
        entity_f: 'Entity',
        action_f: 'Action',
        editor_f: 'Editor',
        company_name_f: 'Company name',
        category_f: 'Category',
      },
    }
  },

  created() {
    this.fetchDataLink_builder_f()
    this.fetchDataCompany_name_f()

    this.entity_f.dataList = (
      this.$store.state.metaData?.history_entity_keys || []
    )
      .sort(function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      })
      .map((item) => {
        return {
          id: item,
          name: item,
        }
      })

    this.category_f.dataList = (
      this.$store.state.metaData?.history_category_keys || []
    )
      .sort(function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      })
      .map((item) => {
        return {
          id: item,
          name: item,
        }
      })

    this.action_f.dataList = (
      this.$store.state.metaData?.history_action_keys || []
    )
      .sort(function (a, b) {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        return 0
      })
      .map((item) => {
        return {
          id: item,
          name: item,
        }
      })

    for (let key in this.filters) {
      const element = this.filters[key]
      if (key in this.$data) {
        this.$data[key].value = element
      }
    }
  },

  methods: {
    changeValueLink_builder_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'link_builder_f', value: value })
      else this.$emit('setFilterValue', { key: 'link_builder_f', value: null })
    },

    changeValuePartner_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'partner_f', value: value })
      else this.$emit('setFilterValue', { key: 'partner_f', value: null })
    },

    changeValueCompany_name_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'company_name_f', value: value })
      else this.$emit('setFilterValue', { key: 'company_name_f', value: null })
    },

    changeValueCategory_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'category_f', value: value })
      else this.$emit('setFilterValue', { key: 'category_f', value: null })
    },

    changeValueEntity_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'entity_f', value: value })
      else this.$emit('setFilterValue', { key: 'entity_f', value: null })
    },

    changeValueEditor_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'editor_f', value: value })
      else this.$emit('setFilterValue', { key: 'editor_f', value: null })
    },

    changeValueAction_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'action_f', value: value })
      else this.$emit('setFilterValue', { key: 'action_f', value: null })
    },

    async fetchDataCompany_name_f() {
      let query = `page=1&per_page=all&company_name=asc`
      let result = await fetchData(urls.partners, 'GET', null, query)

      this.company_name_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.company_name,
          name: item.company_name,
        }
      })
    },

    async fetchDataLink_builder_f() {
      let query = `page=1&per_page=all&first_name=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      this.link_builder_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.first_name + ' ' + item.last_name,
          name: item.first_name + ' ' + item.last_name,
        }
      })

      this.editor_f.dataList = this.link_builder_f.dataList
    },
  },
}
</script>
